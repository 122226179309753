Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "servicespecificsettingsadmin2";
exports.labelBodyText = "servicespecificsettingsadmin2 Body";

exports.btnExampleTitle = "CLICK ME";
exports.textCancel = "Cancel";
exports.listCourseDataEndPoint ='bx_block_account_groups/user_contents/library_courses?';
exports.listProgramDataEndPoint = 'bx_block_account_groups/user_contents/library_programs?';
exports.listContentRoleDataEndPoint="bx_block_account_groups/user_contents/library_content_roles?";
exports.userListEndPoint = "account_block/standard_users";
exports.branchListEndPoint = "account_block/standard_users/branch_list";
exports.createUserAssignContentEndPoint= 'bx_block_account_groups/user_assign_contents';
exports.catagoryAdvanceSearch ="bx_block_advanced_search/search/filter";
exports.courseAndProgramAPIEndPoint = "/bx_block_catalogue/collection_lists";
exports.assignedCoursesLists="bx_block_account_groups/user_contents/course_contents?";
exports.assignedProgramsLists="bx_block_account_groups/user_contents/program_contents?";
exports.assignedContentRoleLists="/bx_block_account_groups/user_contents/content_roles_contents?";
exports.expertAdminListApiEndPoint = "bx_block_library2/experts";
exports.catagoryListingApiEndPoint ="bx_block_categories/categories";
// Customizable Area End