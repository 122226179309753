import React from "react";
import { Typography, Box, Paper } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

interface LessonName {
  id: number;
  name: string;
  program_id: number;
}

interface ColorTag {
  status: string;
  color: string;
  due_date?:string;
}

interface CourseAttributes {
  courseable_id: number;
  course_title: string;
  is_course: boolean;
  lesson_name: LessonName;
  color_tag: ColorTag[];
}

interface Course {
  id: string;
  type: string;
  attributes: CourseAttributes;
}

interface CourseProps {
  course: Course; // The 'Course' interface defined previously
}
export default function CourseCard({ course }: CourseProps) {
  const classes=useStyles();
  const {
    attributes: {
      course_title,
      lesson_name,
      color_tag: [{ status, color,due_date }]
    }
  } = course;
  return (
    <Box style={{boxShadow: "0px 3px 8px -3px #9797974D",width:"100%"}}>
    <Paper className={classes.paper} style={{ borderLeft: `9px solid ${color}`,}}>
      <Box>
        <Typography className={classes.title}>{course_title}</Typography>
        <Typography className={classes.description}>{lesson_name?.name}</Typography>
      </Box>
      <Box>
      <Box>
       { due_date && 
       <Typography className={classes.dueStatusText} style={{color:color}}>
        {due_date}
        </Typography>}
      </Box>      
      <Box className={classes.statusContainer}style={{backgroundColor:color}}>
        <Typography className={classes.statusText}>
        {status}
        </Typography>
      </Box>
      </Box>
    </Paper>
    </Box>

  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "flex-start",
      boxShadow: "0px 3px 8px -3px #9797974D",
      borderRadius: "8px",
      cursor: "pointer",
      padding: "15px 10px",
      [theme.breakpoints.up("md")]: {
        padding: "15px 25px",
        flexDirection: "row",
        alignItems: "center",
      },
    },
    title: {
      fontFamily: 'Poppins',
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: '22px',
      textAlign: 'left',
      color: "#131313",
      marginBottom:"10px",
    },
    description: {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '22px',
      textAlign: 'left',
      color: "#ADAAAA",
      textTransform: "capitalize",
      marginTop: "3px",
    },
    dueStatusText:{
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '22px',
      textAlign: 'center',
      marginBottom:"8px"

    },
    statusText:{
      fontFamily: "Poppins",
      fontSize: "12px",
      fontWeight: 600,
      lineHeight: "18px",
      textAlign: "center",
      color:"#FFFFFF",
    },
    statusContainer: {
      width:"109px",
      height:"22px",
      borderRadius: "8px",
      padding: "auto",
      marginTop: "5px",
      alignContent:"center",
      [theme.breakpoints.up("md")]: {
        marginTop: 0,
      },
    },
    status: {
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "22px",
      textAlign: "center",
      color: "#FFFFFF",
      textTransform: "capitalize",
    },
  })
);
