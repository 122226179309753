// Customizable Area Start
import React from "react";
import ManageAssignController, {
    Library,
    Props,
    configJSON,
} from "./ManageAssignController.web";
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Typography,
    styled,
    Modal,
    Popover,
} from "@material-ui/core";
import AdvancedSearch from "../../advancedsearch/src/AdvancedSearch.web";
import { Filter } from "../../catalogue/src/assets";
import { addContentImg, imageHtmlCourse2, NotFoundImage, rightArrow } from "./assets";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import MaterialUIBox from '@material-ui/core/Box';
import { ExpandMore, ExpandLess } from "@material-ui/icons";
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import CloseIcon from "@material-ui/icons/Close";
import Badge from "@material-ui/core/Badge";
import FilterItems from "../../filteritems/src/FilterItems.web";

class ManageAssign extends ManageAssignController {
    wrapperRefLaunchDate: any;
    wrapperRefDueDate: any;
    constructor(props: Props) {
        super(props);
    }

    generateToggleBoxStyle(isActive: boolean) {
        return {
            backgroundColor: isActive ? "#FFFFFF" : "#FCF8F8",
            height: "48px",
            boxShadow: isActive ? "1px 1px 9px 1px rgba(0, 0, 0, 0.11)" : "",
            color: isActive ? "#1C1B1B" : "",
            display: "flex",
            alignItems: "center",
            width: "170px",
            minWidth: "170px",
            fontSize: "18px",
            fontWeight: isActive ? 600 : 400,
            justifyContent: "center",
            borderRadius: "10px",
            cursor: "pointer",
            maxWidth: "200px",
            padding: "14px 24px"
        };
    }

    renderGroupAssigneeTextFieldBox = () => {
        const { UserData, branchData, selectedGroup, selectedAssignee } = this.state;
        return (
            <>
                <FormControl variant="standard" className="customInput" style={{ width: '392px' }}>
                    <Typography className="labelStyle" >Group:</Typography>
                    <StyledDropdownClickBoxs
                        onClick={this.groupdropdownOpen}
                        data-test-id="select_ID"
                        style={{ color: '#1C1B1B',backgroundColor: this.state.selectedGroup ? '#FFFFFF' : '#FCF6F6'}}
                    >
                        <MaterialUIBox className="contentContainer content_role">
                            <MaterialUIBox 
                                style={{
                                    color: this.state.selectedGroup ? '#1C1B1B' : '#ADAAAA',
                                     fontFamily: "Poppins", fontWeight: 400, fontSize: "16px"
                                }}>
                                {this.state.selectedGroup || 'Select a group of users'}
                            </MaterialUIBox>
                        </MaterialUIBox>
                        {this.state.groupDropdown ? <ExpandLess /> : <ExpandMore />}
                    </StyledDropdownClickBoxs>
                    {this.state.groupDropdown && (
                        <div ref={this.dropdownRef}>
                        <DropdownGroupContent>
                            <MaterialUIBox className="checkboxList">
                                <RadioGroup
                                    data-test-id="radioGroup"
                                    aria-labelledby="group-selection"
                                    name="group"
                                    value={this.state.selectedGroup}
                                    onChange={this.handleGroupChange}
                                >
                                    <FormControlLabel className={"checkboxContent"} value="Users" control={<Radio style={{ color: "#652786" }} />} label="Users" />
                                    <FormControlLabel className={"checkboxContent"} value="Branches" control={<Radio style={{ color: "#652786" }} />} label="Branches" />
                                </RadioGroup>
                            </MaterialUIBox>
                        </DropdownGroupContent>
                        </div>
                    )}
                </FormControl>
                <FormControl variant="standard" className="customInput" style={{ width: '392px' }}>
                    <Typography className="labelStyle" >Assignee:</Typography>
                    <StyledDropdownClickBoxs
                        onClick={selectedGroup ? this.assigneedropdownOpen : undefined}
                        data-test-id="select_ID"
                        style={{backgroundColor: selectedAssignee ? '#FFFFFF' : '#FCF6F6'}}
                    >
                        <MaterialUIBox className="contentContainer content_role">
                            <MaterialUIBox className="innerContentBox"
                                style={{
                                    color: selectedAssignee ? '#1C1B1B' : '#ADAAAA',
                                     fontFamily: "Poppins", fontWeight: 400, fontSize: "16px"
                                }}>
                                {selectedAssignee || 'Select group first'}
                            </MaterialUIBox>
                        </MaterialUIBox>
                        {this.state.assigneeDropdown ? <ExpandLess /> : <ExpandMore />}
                    </StyledDropdownClickBoxs>
                    {this.state.assigneeDropdown && (
                        <div ref={this.dropdownRef}>
                        <DropdownContent>
                            <TextFieldSearch
                            data-test-id="userBranchSearch"
                            variant="outlined"
                            placeholder="Search"
                            value={selectedGroup === 'Users' ? this.state.placeholderSearchUsersText : this.state.placeholderSearchBranchText}
                            onChange={this.handlePlaceholderUserBranchSearchChanges}
                            InputProps={{
                                startAdornment: (
                                <SearchIcon />
                                ),
                            }}
                            />
                            <MaterialUIBox className="checkboxList">
                                <RadioGroup
                                    data-test-id="radioGroup2"
                                    aria-labelledby="group-selection"
                                    name="group"
                                    value={this.state.selectedAssignee}
                                    onChange={this.handleAssigneeChange}
                                >
                                    {selectedGroup === 'Users' && UserData.map(user => (
                                        <FormControlLabel
                                            key={user.id}
                                            className={"checkboxContent"}
                                            value={user.attributes.full_name}
                                            control={<Radio style={{ color: "#652786" }} />}
                                            label={user.attributes.full_name}
                                            onClick={() => {this.handleUserManageData(user.id)}}
                                        />
                                    ))}
                                    {selectedGroup === 'Branches' && branchData.map(branch => (
                                        <FormControlLabel
                                            key={branch.id}
                                            className={"checkboxContent"}
                                            value={branch.attributes.name}
                                            control={<Radio style={{ color: "#652786" }} />}
                                            label={branch.attributes.name}
                                            onClick={() => {this.handleBranchManageData(branch.id)}}
                                        />
                                    ))}
                                </RadioGroup>
                            </MaterialUIBox>
                        </DropdownContent>
                        </div>
                    )}
                </FormControl>
            </>
        );
    }

    renderCoursesProgramSearchBox() {
        const { isCourseActive, isCourseEnabled, isProgramEnabled, isContentRoleEnabled, anchorFilterContainerEl } = this.state;
        const courseToggleStyle = this.generateToggleBoxStyle(isCourseActive === "course" && isCourseEnabled);
        const programToggleStyle = this.generateToggleBoxStyle(isCourseActive === "program" && isProgramEnabled);
        const contentRoleToggleStyle = this.generateToggleBoxStyle(isCourseActive === "content_role" && isContentRoleEnabled);
        const openFilterContainer = Boolean(this.state.anchorFilterContainerEl)
        return (
            <Grid item container style={{ margin: "20px", gap: "clamp(5px, 45px, 3%)" }} >
                <TabGrid >
                    <ToggleBox >
                        <ToggleInnerbox data-test-id="courseToggleId"
                            style={courseToggleStyle} onClick={this.handleToggleCourses} >
                            Course
                        </ToggleInnerbox>
                        <ToggleInnerbox data-test-id="programToggleId"
                            style={programToggleStyle} onClick={this.handleTogglePrograms}>
                            Program
                        </ToggleInnerbox>
                        <ToggleInnerbox data-test-id="contentRoleToggleId"
                            style={contentRoleToggleStyle} onClick={this.handleToggleContentRole}>
                            Content Role
                        </ToggleInnerbox>
                    </ToggleBox>
                </TabGrid>

                <InputMainBox style={{
                    display: 'flex', alignItems: 'center',
                    borderRight: "none", justifyContent: "center", width: "clamp(300px, 518px, 45%)"
                }}>
                    <StyledContentWrapper>
                        <AdvancedSearch
                            data-test-id="btn_chanLibraryBoxge"
                            searchInputValue={this.state.searchInputValue}
                            handleListItemClick={this.handleListItemClickSuggestion}
                            filterList={this.state.filteredList}
                            handleInputChange={this.handleInputCatalogueChangeSearch}
                            isFilterDropActive={openFilterContainer}
                            inputWidth="100%"
                            showList={this.state.showList} navigation={undefined} id={""} />
                         <div style={{ marginLeft: "0px" }} className={anchorFilterContainerEl ? "filterIconBox allBorder" : "filterIconBox"}  onClick={this.handleFilterDrop} aria-describedby={"myPopover"}>
              {this.state.creationDate.length > 0 || (this.state.creationDateParams.hasOwnProperty('start_date') && this.state.creationDateParams.start_date.length > 0) ||this.state.categorySelectedItems.length > 0|| this.state.placeholderSelectedItems.length > 0  || this.state.contentRoleSelectedItems.length > 0 || this.state.selectedStatus.length > 0 ? (
                <BadgeComponents color="secondary" variant="dot"></BadgeComponents>) : (
                ""
              )
              }
              {anchorFilterContainerEl ? (
                <FiterImage src={Filter} data-test-id="filterOpenTestID" alt="Filter" />
              ) : (
                <img src={Filter} data-test-id="filterOpenTestID" />
              )}
            </div>
            <Popover
              data-test-id="filterPropsID"
              open={openFilterContainer}
              className="filterContainerPopover"
              id="myPopover"
              onClose={this.filterContainerDropdownCloseHandler}
              anchorEl={this.state.anchorFilterContainerEl}
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
              }}
            >
              <FilterItems
                isContentRole={this.state.contentRoleTrue}
                data-test-id="FilterItemsTestID"
                selectedStatus={this.state.selectedStatus}
                handleChangeStatus={this.handleCatalogueChangeStatus}
                handleClick={this.handleClickFilter}
                contentRoleCheckboxChangeHandler={this.contentRoleCheckboxHandlerChange}
                handleContentRoleSearchChange={this.handleContentRoleChangeSearch}
                categoryCheckboxChangeHandler={this.categoryCheckboxHandlerChange}
                handleCategorySearchChange={this.handleCategoryChangeSearch}
                categorySelectedItems={this.state.categorySelectedItems}
                contentRoleSearchText={this.state.contentRoleSearchText}
                contentRoleSelectedItems={this.state.contentRoleSelectedItems}
                categorySearchText={this.state.categorySearchText}
                placeholderCheckboxChangeHandler={this.placeholderCheckboxAssignedExpertChangeHandler}
                handlePlaceholderSearchChange={this.handlePlaceholderAssignedSearchChange}
                creationDateChangeHandler={this.creationChangeHandlerDate}
                dropdownOpenHandler={this.dropdownHandlerOpen}
                dropdownCloseHandler={this.dropdownHandlerClose}
                handleCalendarOpen={this.handleOpenCalendor}
                anchorEl={this.state.anchorEl}
                dropdownType={this.state.dropdownType}
                placeholderSelectedItems={this.state.placeholderSelectedItems}
                placeholderSearchText={this.state.placeholderSearchText}
                creationDate={this.state.creationDate}
                expertAdminList={this.state.expertAdminList}
                adminCatagoryList={this.state.adminCatagoryList}
                adminContentRolesList={this.state.adminContentRolesList}
                isCalendarOpen={this.state.isCalendarOpen}
                handleClear={this.handleClearFilter}
                handleCalendarChange={this.handleChangeCalendor} />
            </Popover>
           
                    </StyledContentWrapper>
                </InputMainBox>
            </Grid>
        )
    }

    renderTableData = (libraryStaticData: Library[], handleChange: (selectedData: Library) => void,
        tableName: string, checked: Library[]) => {
        return (
            <Box style={{ overflowY: "scroll", width: "100%", height: "588px" }} >
                {libraryStaticData.map((data, index) => (

                    <Box style={{
                        display: "flex", justifyContent: "space-between", alignItems: "center",
                        boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.15)", padding: "16px 24px", borderRadius: "12px",
                        maxWidth: "588px", height: "72px",cursor:"pointer",
                        backgroundColor: this.isCheckedValue(checked, data) ? "rgba(238, 232, 242, 1)" : "#fff", marginBottom: "10px"
                    }}  data-test-id="selectedBox" onClick={() => handleChange(data)}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Checkbox
                                className="checkBox"
                                style={{ color: this.isCheckedValue(checked, data) ? "#652786" : "grey", textTransform: "capitalize" }}
                                checked={this.isCheckedValue(checked, data)}
                                data-test-id={`selectedCheckBox${index}`}
                                onChange={(event) => {
                                    event.stopPropagation(); 
                                    handleChange(data);
                                }}
                            />
                            <div style={{ display: "flex", alignItems: "center", gap: "14px", }}>
                                <div>
                                    <img style={thumb_img} src={data?.attributes?.thumbnail_image?.url ? data?.attributes.thumbnail_image.url : imageHtmlCourse2} />
                                </div>

                                <TitleData>{data?.attributes?.title}</TitleData>
                            </div>
                        </div >

                        <Statuses style={webStyle.statusNew} >{data?.attributes?.status}</Statuses>
                    </Box>
                ))}
            </Box>
        )
    }

    renderProgramCLickedData = () => {
        return (
            <>
                {this.state.removedAllData.length > 0 ?
                    (this.renderTableData(this.state.removedAllData, this.handleRemoveCheckboxChangeProgram, "",
                        this.state.removedProgramData)
                    ) : (<Box style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "10px"
                    }}>
                        <Addedimg src={addContentImg} data-test-id="filterOpenTestID" />
                        <Typography style={webStyle.staticDataStyle}
                        >Select the content you want to add</Typography>
                    </Box>)}

                <Box style={webStyle.removeButtonBoxstyle}>
                    <Button style={this.state.removedProgramData.length? webStyle.removeButtonStyleAfter : webStyle.removeButtonStyleBefor}
                        data-test-id="removeButtonId"
                        disabled={this.state.removedProgramData.length?false:true}
                        onClick={this.handleRemoveButtonClickProgram}>
                        <KeyboardBackspaceIcon />
                        &nbsp;&nbsp;&nbsp;
                        <p style={{ textTransform: "capitalize" }} >Remove</p>
                    </Button>
                </Box>
            </>
        )
    }

    libraryDataCheck = (data: any, value: any) => {
        if (data.length > 0) {
            return this.renderTableData(value, this.handleCheckboxChange,
                "data", this.state.selectedContent)
        }
        else {
            return <Box style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                gap: "10px",
                height: "695px"
            }}>
                <NoDataImg src={NotFoundImage} data-test-id="filterOpenTestID" />
                <Typography style={webStyle.staticDataStyle}
                >No results founds</Typography>
            </Box>
        }
    }

    renderDataBox() {
        const libraryData = this.state.libraryData.filter((library) => !this.state.removedAllData.includes(library))
        const programData = this.state.programData.filter((library) => !this.state.removedAllData.includes(library))
        const contentRoleData = this.state.contentRoleData.filter((library) => !this.state.removedAllData.includes(library))


        return (

            <LibraryBox >
                <Grid container spacing={2}>

                    <Grid item xs={12} md={6} lg={6}  >
                        <Box style={{
                            display: "flex", flexDirection: "column", gap: "24px",
                            boxShadow: "1px 2px 10px 0px rgba(0, 0, 0, 0.07)",
                            height: "695px", padding: "20px", borderRadius: "16px"
                        }}>
                            <Box>
                                <Typography style={headStyle}>Library</Typography>
                            </Box>
                            {(() => {
                                if (this.state.isCourseActive === "course" && this.state.isCourseEnabled) {
                                    return this.libraryDataCheck(this.state.libraryData, libraryData);
                                } else if (this.state.isCourseActive === "program" && this.state.isProgramEnabled) {
                                    return this.libraryDataCheck(this.state.programData, programData);
                                } else if (this.state.isCourseActive === "content_role" && this.state.isContentRoleEnabled) {
                                    return this.libraryDataCheck(this.state.contentRoleData, contentRoleData);
                                } else {
                                    return null;
                                }
                            })()}
                            <Box style={webStyle.addButtonBoxstyle}>
                                <Button
                                    className={!this.state.selectedContent.length ? "add_btn" : "add_btn enable_btn"}
                                    disabled={!this.state.selectedContent.length}
                                    onClick={this.handleAddButtonClick}
                                    data-test-id="testing">
                                    <Typography
                                        style={typo2}>Add</Typography>
                                    &nbsp;&nbsp;&nbsp;
                                    <img src={rightArrow}
                                        alt="arrow"
                                    />
                                </Button>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={6} lg={6}>
                        <Box style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            flexDirection: "column",
                            boxShadow: " 0px 2px 8px 0px rgba(0, 0, 0, 0.07)",
                            height: "695px", padding: "20px", gap: "16px"
                            , top: "616px", left: "134px", borderRadius: "16px"
                        }} >
                            <Box style={{ width: "100%" }}>
                                <Typography style={headStyle}>Assigned</Typography>
                            </Box>
                            {this.renderProgramCLickedData()}
                        </Box>
                    </Grid>
                </Grid>
            </LibraryBox>
        )
    }

    render() {
        const isDisable=this.handleCreateTeamDisable();
        return (
            <>
                <Box sx={gridStyle} style={{...webStyle.dropdownContainer,flexDirection: "row"}}>
                    {this.renderGroupAssigneeTextFieldBox()}
                </Box>
                <Grid container style={{ marginTop: '20px' }}>
                    {this.renderCoursesProgramSearchBox()}
                </Grid>
                <Grid container style={{ marginTop: '20px' }}>
                    {this.renderDataBox()}
                </Grid>
                <MainButton   >
                    <SaveAndCreate
                        data-test-id="saveChangesBtn"
                        onClick={() => {
                            this.postUserAssign(this.state.removedAllData);
                        }}
                        style={isDisable ? webStyle.saveChangesEnableBtn : webStyle.saveChangesDisableBtn}
                        disabled={isDisable?false:true}
                    >
                        <Typography style={typo}>Save Changes</Typography>
                    </SaveAndCreate>
                </MainButton>
                {<ModalMainBody
                    open={this.state.openGroupDialog}
                    >
                    <ModalContent>
                        <IconAlign onClick={this.cancelGroupDialog}><CloseIcon /></IconAlign>
                        <ModalText>{`Are you sure you want to leave?`}</ModalText>
                        <ModalText1>{"All your changes will be lost."}</ModalText1>
                        <div style={{ width: "95%",paddingTop:"20px" }}>
                        <div style={{...webStyle.buttonContainer,flexWrap:"wrap"}}>
                            <button data-test-id="cancelGroupData" style={{...webStyle.cancelButton,textTransform:"none"}} onClick={this.cancelGroupDialog}>Continue</button>
                            <button data-test-id="groupLeaveData"  style={{...webStyle.leaveButton,textTransform:"none"}} onClick={this.groupLeaveDialog}>{configJSON.textCancel}</button>
                        </div>
                        </div>
                    </ModalContent>
                    </ModalMainBody>
                }
            </>
        )
    }
}

const thumb_img = {
    width: "40px",
    height: "40px",
    borderRadius: "4px",
    border: "1px solid #929090",
    boxShadow: "0px 2px 14px 0px #8C8C8CB6"
}

const headStyle = {
    fontFamily: "Poppins",
    fontSize: "22px",
    fontWeight: 600,
    lineHeight: "33px",
    color: "#1C1B1B"
};

const typo = {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "16px",
    textTransform: "none" as 'none'
}

const typo2 = {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "16px",
    textTransform: "capitalize" as 'capitalize',
    color: "#FFFFFF"
}

const gridStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    boxShadow: "1px 2px 10px 0px rgba(0, 0, 0, 0.07)",
    padding: "24px",
    flexWrap: "wrap",
    width: "100%",
    borderRadius: "18px",

};
const webStyle = {
    leaveButton: {
        width: "48%",
        border: "none",
        height: "42px",
        background: "#652786",
        borderRadius: "12px",
        fontWeight: 600,
        fontSize: "16px",
        color: "white",
        textTransform: "none",
        cursor: "pointer"
      },
    cancelButton: {
        width: "48%",
        height: "42px",
        fontWeight: 600,
        background: "white",
        fontSize: "16px",
        border: "1px solid #E47004",
        borderRadius: "12px",
        color: "#E47004",
        textTransform: "none",
        cursor: "pointer"
      },
    buttonContainer: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        padding: "0px 2px 0px 2px"
      },
    addButtonBoxstyle: {
        display: "flex",
        padding: "16px 16px 20px",
        justifyContent: "flex-end",
        alignItems: "end",
        gap: "24px"
    },
    removeButtonBoxstyle: {
        display: "flex",
        width: "100%",
        height: "74px",
        padding: "16px 16px 20px",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "24px",
    },
    removeButtonStyleBefor: {
        display: "flex",
        width: "165px",
        height: "42px",
        padding: "12px 24px 12px 16px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        flexShrink: 0,
        borderRadius: "12px",
        border: "1px solid #E5E2E1",
        color: "#929090",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "16px",
        backgroundColor: "#FFFFFF"
    },
    removeButtonStyleAfter: {
        display: "flex",
        width: "165px",
        height: "42px",
        padding: "12px 24px 12px 16px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        flexShrink: 0,
        borderRadius: "12px",
        border: "1px solid #E47004",
        color: "#E47004",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "16px",


    },
    staticDataStyle: {
        fontFamily: "Poppins",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "24px",
        color: "#1C1B1B",
    },
    saveChangesDisableBtn: {
        display: "flex",
        padding: "20px 32px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        border: "1px solid #E5E2E1",
        borderRadius: "16px",
        color: "#929090",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 600,
        width: "411px",
        height: "56px"
    },
    dropdownContainer: {
        display: "flex", 
        width: "62.4%"
    },
    saveChangesEnableBtn: {
        width: "411px",
        height: "56px",
        display: "flex",
        padding: "20px 32px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        border: "1px solid #E47004",
        borderRadius: "16px",
        color: "#E47004",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 600,

    },
    statusNew: {
        display: 'flex',
        width: "125px",
        height: "40px",
        padding: "6px 9px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        borderRadius: "88px",
        background: "#E0D4E7",
        color: "#542070",
        textTransform: "capitalize" as 'capitalize',
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 400,
    },
}
const MainButton = styled(Box)({
    display: "flex",
    padding: "38px 32px 20px 32px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    flexDirection: "column",
});

const SaveAndCreate = styled(Button)({
    width: "411px",
    height: "56px",
    padding: "20px 32px",
    "@media (max-width:960px)": {
        width: "240px",
        height: "56px",
        padding: "10px 21px",
    },
})

const TitleData = styled("div")({
    width: "300px",
    color: "#484646",
    fontSize: "16px",
    fontFamily: "Poppins",
    fontWeight: 400,
    lineHeight: "22px",
    textTransform: "capitalize" as 'capitalize',
    "@media (max-width:960px)": {
        wordBreak: "break-all",
        width: "56px",
    },
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "inline-block"
});

const Statuses = styled("div")({
    backgroundColor: "#E0D4E7",
    textAlign: "center",
    padding: "6px 9px",
    color: "#542070",
    fontSize: "14px",
    borderRadius: "88px",
    gap: "10px",
    textTransform: "capitalize",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    width: "125px",
    height: "40px",
    "@media (max-width:960px)": {
        fontSize: "10px",
        padding: "6px 9px",
    },
});

const TabGrid = styled(Box)({
    display: "flex",
    flexWrap: "wrap"
})

const LibraryBox = styled(Box)({
    gap: "10px",
    width: "100%",
    "& .add_btn": {
        display: "flex",
        height: "42px",
        width: "165px",
        padding: "12px 24px 12px 16px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        backgroundColor: "#E5E2E1",
        flexShrink: 0,
        borderRadius: "12px",
        color: "#929090",
        cursor: "pointer"
    },
    "& .enable_btn": {
        backgroundColor: "#652786 !important",
        color: "#FFFFFF !important"
    }
})

const ToggleBox = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: "#FCF8F8",
    alignItems: "center",
    borderRadius: "12px ",
    cursor: "pointer",
    flexWrap: "wrap"
});

const FiterImage = styled("img")({
});

const StyledContentWrapper = styled(Box)({
    position: "relative",
    display: "flex",
    width: "100%",
    "& .filterIconBox": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#FAFAFA",
        width: "63px",
        borderRadius: "8px",
        borderTopLeftRadius: "8px !important",
        borderBottomLeftRadius: "8px !important",
        cursor: "pointer",
        border: "1px solid #C9C6C5",
        position: "relative",
        zIndex: 999,
        left: "-1%"
    },
    "& .filterIconBox:hover": {
        borderColor: '#652786'
    },
    "& .allBorder": {
        borderColor: '#652786',
        backgroundColor: "#EEE8F2",
    }
});

const ToggleInnerbox = styled(Box)({
    color: "#1c1818",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
});

const Addedimg = styled("img")({
    "@media (max-width:960px)": {
        width: "250px"
    },
})

const NoDataImg = styled("img")({
    height: "80%",
    width: "80%",
    "@media (max-width:960px)": {
        width: "250px"
    },
})

const InputMainBox = styled(Grid)({
    '& .MuiOutlinedInput-adornedEnd': {
        paddingRight: '0px',
    }
});

const StyledDropdownClickBoxs = styled('div')({
    width: "392px",
    height: "52px",
    marginTop: "8px",
    display: "flex",
    alignItems: "center",
    gap: "12px",
    padding: "16px 20px !important",
    border: "1px solid #E5E2E1",
    borderRadius: "8px",
    backgroundColor: "#FFFFFF",
    cursor: "pointer",
    position: "relative",
    "@media (max-width:1000px)": {
        width: "100%",
        height: "52px",
    },
    "&.selectCommonStyle .contentContainer": {
        display: "flex",
        gap: "3px",
        width: "100%"
    },
    "&.selectActive": {
        backgroundColor: "#EEE8F2",
    },
    "&.selectActive .title": {
        color: "#652786"
    },
    "&.creationDate": {
        display: "flex",
        justifyContent: "space-between"
    },
    "& .content_role ": {
        width: "100% !important",
        display: "flex !important",
        justifyContent: "space-between !important"
    },
    "& .innerContentBox": {
        display: "flex",
        gap: "3px",
        fontSize: "16px",
        fontWeight: 500,
        color: '#ADAAAA'
    },
    "& .title": {
        whiteSpace: "nowrap",
        fontSize: "16px",
        fontWeight: 400,
        color: "#484646",
        fontFamily: "Poppins, sans-serif"
    },
    "& .arrowIconStyle": {
        color: "#652786"
    },
    "&:focus": {
        outline: "none",
        border: "1px solid #652786"
    },
    "&:hover": {
        border: "1px solid #652786"
    }
});

const DropdownContent = styled(MaterialUIBox)({
    position: "absolute",
    top: "100px",
    zIndex: 9999,
    background: "#fff",
    width: "100%",
    height: "330px",
    overflowY: "scroll",
    boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.15)',
    padding: "8px 16px",
    borderRadius: "12px",
    "& .checkboxList": {
        marginTop: "5px",
        flexDirection: "column",
        display: "flex",
        gap: "8px",
        marginBottom: "5px"
    },
    "& .checkboxContent": {
        display: "flex",
        alignItems: "center",
        fontFamily: "Poppins , sans-serif",
        borderBottom: "1px solid transparent",
        textTransform: "capitalize"
    }, "& .MuiTypography-body1": {
        fontFamily: "Poppins, sans-serif",
        fontSize: "16px",
        fontWeight: 400,
        color: "#1C1B1B"
    },
    "& .checkboxContent:hover": {
        borderBottom: "1px solid transparent",
        backgroundColor: "#EEE8F2",
        borderRadius: "8px",
    },
    "& .selectedStyle": {
        borderBottom: "1px solid transparent !important",
        backgroundColor: "#EEE8F2",
        borderRadius: "8px",
    },
    "& .selectedStyle .MuiTypography-body1": {
        color: "#542070 !important"
    },
    "& .applyBtn": {
        display: "block",
        margin: "auto",
        textTransform: "capitalize",
        color: "#542070",
        fontSize: "16px"
    },
    "& .MuiFormControlLabel-root": {
        margin: 0,
        borderBottom: "1px solid transparent",
        fontFamily: "Poppins, sans-serif"
    },
});

const DropdownGroupContent = styled(MaterialUIBox)({
    position: "absolute",
    top: "100px",
    zIndex: 9999,
    background: "#fff",
    width: "100%",
    padding: "8px 16px",
    boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.15)',
    borderRadius: "12px",
    "& .checkboxList": {
        flexDirection: "column",
        marginTop: "5px",
        gap: "8px",
        display: "flex",
        marginBottom: "5px"
    },
    "& .checkboxContent": {
        display: "flex",
        fontFamily: "Poppins , sans-serif",
        alignItems: "center",
        textTransform: "capitalize",
        borderBottom: "1px solid transparent",
    }, "& .MuiTypography-body1": {
        fontFamily: "Poppins, sans-serif",
        fontWeight: 400,
        fontSize: "16px",
        color: "#1C1B1B"
    },
    "& .checkboxContent:hover": {
        borderBottom: "1px solid transparent",
        borderRadius: "8px",
        backgroundColor: "#EEE8F2",
    },
    "& .selectedStyle": {
        borderBottom: "1px solid transparent !important",
        borderRadius: "8px",
        backgroundColor: "#EEE8F2",
    },
    "& .selectedStyle .MuiTypography-body1": {
        color: "#542070 !important"
    },
    "& .applyBtn": {
        margin: "auto",
        display: "block",
        textTransform: "capitalize",
        fontSize: "16px",
        color: "#542070",
    },
    "& .MuiFormControlLabel-root": {
        borderBottom: "1px solid transparent",
        margin: 0,
        fontFamily: "Poppins, sans-serif"
    },
});

const TextFieldSearch = styled(TextField)({
    "& .MuiOutlinedInput-notchedOutline":{
      backgroundColor: "#fcf6f6",
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "lightgray"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "lightgray"
    },
    "& .MuiSvgIcon-root":{
      position: "relative",
      zIndex: 1,
      color: "gray"
    },
    "& .MuiOutlinedInput-root":{
      borderRadius: "12px",
      height: "48px"
    },
    "& .MuiOutlinedInput-input":{
      position: "relative",
      zIndex: 1,
    },
    "& .MuiOutlinedInput-inputAdornedStart":{
      paddingLeft: "6px"
    }
  });

  const ModalMainBody = styled(Modal)({
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  });
  
  const ModalContent = styled(Box)({
    position: "absolute",
    maxWidth: "525px",
    width: "100%",
    height: "auto",
    padding: "9px",
    backgroundColor: "#fff",
    display: "flex",
    alignContent: "center",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "12px",
    paddingTop: "20px",
    paddingBottom: "25px",
    "@media (max-width: 656px)": {
      maxWidth: "400px",
    },
    "@media (max-width: 400px)": {
      maxWidth: "330px",
    }
  });
  
  const ModalText = styled(Typography)({
    width: "100%",
    display: "flex",
    fontStyle: "Poppins",
    fontWeight: 600,
    fontSize: "22px",
    justifyContent: "left",
    padding: "5px 20px 5px 20px"
  });
  
  const ModalText1 = styled(Typography)({
    width: "100%",
    display: "flex",
    fontStyle: "Poppins",
    fontWeight: 400,
    fontSize: "16px",
    justifyContent: "left",
    padding: "5px 20px 5px 20px"
  });
  
  const IconAlign = styled(Box)({
    position: "absolute",
    top: "-10",
    right: "-10",
    backgroundColor: "#652786",
    borderRadius: "50%",
    color: "white",
    cursor: 'pointer !important'
  });

  const BadgeComponents = styled(Badge)({
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      top: "-14px",
      left: "20px",
      right: " 0px",
      backgroundColor: " #FF883F",
      fontSize: "81px",
      width: "10px",
      height: "10px",
      borderRadius: "20px"
    }
  });

export default ManageAssign;
// Customizable Area End