Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.apiContentType = "application/json";
exports.getApiMethod = "GET";
exports.postApiMethod = "POST";
exports.putApiMethod = "PUT";
exports.deleteApiMethod = "DELETE";

exports.groupsApiEndPoint = "account_groups/groups";
exports.tasksAccountsApiEndPoint = "roles_permissions/accounts/list_users";

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "* All fields are mandatory.";

exports.textClose = "Close";
exports.textSave = "Save";
exports.textUpdate = "Update";
exports.textAddGroup = "Add Group";
exports.textShowGroup = "Show Group";
exports.textEdit = "Edit";
exports.textDelete = "Delete";
exports.textAccounts = "Accounts";
exports.textAddAcc = "Add Acc.";
exports.textDeleteAcc = "Delete Acc.";
exports.textId = "Id";
exports.textStatus = "Status";
exports.textActions = "Actions";
exports.textAssign = "Assign";
exports.textAssignedTo = "Assigned To";
exports.textName = "Name";
exports.textCreatedAt = "Created At";
exports.textUpdatedAt = "Updated At";
exports.textSelectAccounts = "Select accounts";
exports.mobileNumber = "Mobile Number";
exports.emailAddress = "Email Address*";
exports.title = "Title";
exports.lastName = "Last Name*";
exports.middleName = "Middle Name";
exports.firstName = "First Name*";
exports.userInformation = "User Information";
exports.saveChange = "Save Changes";
exports.emailText = "This will be the account ID";
exports.inviteUser = "Invite User";
exports.userName = "Name:";
exports.invited = "Invited";
exports.emailAddress2 = "Email Address";
exports.deleteUser = "Delete User";
exports.resendInvite = "Resend Invite";
exports.assignContent = "Assign Content";
exports.addContent = "Add to Teams";
exports.userInformationText = "Select the content this user will be allowed to view";
exports.userInformationText2 = "Add the user to teams so is easier to assign content in the branches";
exports.teams = "Teams";
exports.addTeams = "Add to Teams";
exports.sendLinkTo = "Send link to:";
exports.email = "Email";

exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "UserGroups2";
exports.labelBodyText = "UserGroups2 Body";
exports.btnExampleTitle = "CLICK ME";
exports.apiContentType = "application/json";
exports.getApiMethod = "GET";
exports.postApiMethod = "POST";
exports.userListEndPoint = "account_block/standard_users";
exports.userSuggestionsEndPoint = "bx_block_teambuilder/usermanage/user_search";
exports.listOfTeamsAndBranchesEndPoint = "bx_block_teambuilder/usermanage/branch_list";
exports.listOfCoursesPorgramsCRolesCBundlesEndPoint =
    "bx_block_coursecreation/collections/course_program_list?subscription_course=true&subscription_program=true";
exports.createUserEndPoint = "account_block/standard_users";
exports.contentsToAssignEndPoint = "bx_block_coursecreation/courses/get_collection";
exports.CountryFlagApiEndPoint = "account_block/accounts/country_code_and_flags";
exports.teamListingApiEndPoint = "/account_block/standard_users/team_list";
exports.BranchesListingApiEndPoint = "/account_block/standard_users/branch_list";
exports.advanceSearchApiEndPoint = "/bx_block_advanced_search/search/filter";
exports.listCourseDataEndPoint ='bx_block_account_groups/user_contents/library_courses?';
exports.listProgramDataEndPoint = 'bx_block_account_groups/user_contents/library_programs?';
exports.createUserAssignContentEndPoint= 'bx_block_account_groups/user_assign_contents';
exports.createUserAssignEndPoint='bx_block_account_groups/user_assign_contents?'
exports.assignedCoursesLists="bx_block_account_groups/user_contents/course_contents?";
exports.assignedProgramsLists="bx_block_account_groups/user_contents/program_contents?";
exports.catagoryAdvanceSearch ="bx_block_advanced_search/search/filter";
exports.courseAndProgramAPIEndPoint = "/bx_block_catalogue/collection_lists";
exports.expertAdminListApiEndPoint = "bx_block_library2/experts";
exports.catagoryListingApiEndPoint ="bx_block_categories/categories";
// Customizable Area End
