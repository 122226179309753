import React from 'react';
import { Typography, Button, Box, styled, DialogTitle, Dialog, TableHead, TableRow, TableCell, Badge, TableSortLabel, Checkbox, TableBody, Modal,Popover} from '@material-ui/core';
import CourseCreationController, { AttributesType, Props } from './CourseCreationController.web';

import { NewFilter, NotFoundImage, Filter, BackgroundColor } from '../src/assets';
import './CourseCreation.css';
import { BackArrow, UnlinkIcon} from './assets';
import { DownArrowIcon, UpArrowIcon, editNew} from "../../../components/src/Table/asset";
import AdvancedSearch from "../../../blocks/advancedsearch/src/AdvancedSearch.web";
import FilterItems from "../../filteritems/src/FilterItems.web";
import DeleteModal from "../../../components/src/DeleteDialog/DeleteModal";
import PhaseDragDrop from '../../../components/src/LessonDragDrop/PhaseDragDrop';
import CloseIcon from '@material-ui/icons/Close';


const CreateNewVideoComponent = styled(Typography)({
  margin: 'auto',
  fontStyle: 'normal',
  display: 'flex',
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '16px',
  textAlign: 'center',
  color: '#FFFFFF',
  textTransform: 'capitalize',
});

const webStyle = {
  AccordionSummary: {
    height: '42px',
    backgroundColor: '#6C328B',
    borderRadius: '12px',
    width: '165px',
    padding: "12px 24px 12px 16px",
    marginTop: '32px',
  },
  AccordionCourseSummary:  {
    height: '42px',
    backgroundColor: '#6C328B',
    borderRadius: '12px',
    width: '165px',
    padding: "12px 24px 12px 16px",
    marginLeft: 'auto'
  },
  
  statusNew: {
    display: 'flex',
    width: "96px",
    height: "40px",
    padding: "6px 9px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "88px",
    background: "#EEE8F2",
    color: "#652786",
    textTransform:"capitalize" as 'capitalize'
    
  },
  statusDraft: {
    display: 'flex',
    borderRadius: "88px",
    padding: "6px 9px",
    textTransform:"capitalize" as 'capitalize',
    justifyContent: "center",
    gap: "10px",
    background: "#FFEDE4",
    color: "#E47004",
    width: "96px",
    height: "40px",
    alignItems: "center",
  },
  statusArchived: {
    display: 'flex',
    padding: "6px 9px",
    borderRadius: "88px",
    justifyContent: "center",
    alignItems: "center",
    textTransform:"capitalize" as 'capitalize',
    gap: "10px",
    background: "#FFD9DF",
    color: "#E70E6B",
    width: "96px",
    height: "40px",
  },
  buttonsLesson: {
    display: "flex",
    gap: "10px",
    flexWrap: "wrap",
    justifyContent: { xs: "center", md: "end" },
    alignItems: "center",
    cursor: "pointer"
  },
  containtContainer2: {
    borderRadius: "16px",
    height: "auto"
  },
  addPhasesButton: {
    width: "164px",
    height: "36px",
    padding: "6px 10px 6px 10px",
    display: "flex",
    gap: "10px",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  addPhaseButtonText: {
    fontFamily: "'Poppins', sans-serif" as "'Poppins', sans-serif",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "16px",
    textAlign: "center" as "center",
    color: "rgba(101, 43, 133, 1)",
    textTransform: "capitalize" as "capitalize",
    textDecoration:"underline",
    textDecorationColor:"#652B85"
  },
  lessonRightContainer: {
    display: "flex",
    alignItems: "center",
    gap: "6px"
  },
  mainLessonWrapper: {
    minHeight: "59px",
    boxShadow: "1px 2px 10px 0 rgba(0, 0, 0, 0.07)",
    backgroundColor: "#EEE8F2",
    padding: "17px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 'auto'
  },
  modalMainHeading: {
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 600,
    fontSize: "21px",
    lineHeight: "33px",
    color:"#000"
  },
  modalSubHeading: {
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "22px",
    display:"flex",
    gap:"5px",
    alignItems:"center",
    color:"#000"
  },
  hoverContainer: {
    height: 0,
    border: "4px solid #B293C2",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  buttonText: {
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "16px",
    textAlign: "center" as "center",
    color:"#ffffff",
    textTransform:"capitalize" as const,
  },
    saveButtonText: {
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "16px",
    textAlign: "center" as "center",
    color:"#542070",
    textTransform:"capitalize" as const,
    margin:"0px 5px"
  },
  dropDownArrow: {
    width: "24.66px",
    height: "24px",
  },
  innerLeftContainer: {
    display: "flex",
    gap: "16px"
  },
  secondContainerLesson: {
    backgroundColor: "#F4F0EF"
  },
  innerRightContainer: {
    display: "flex",
    gap: "16px",
    justifyContent: "center",
    alignItems: "center"
  },
  imageContainerLesson: {
    display: "flex",
    justifyContent: "center",
    alignItems: 'center',
    height: "178px",
    width: "271.61px"
  },
  secondaryButton: {
    width: "162px",
    height: "42px",
    borderRadius: "12px",
    gap: "16px",
    color: "#FFFFFF",
  },  
  dialogRenamePhases: {
    backgroundColor: "#FFFFFF",
    height: "40px",
    zIndex: 9,
    position: "absolute" as "absolute",
    left: "60px",
    borderRadius: "12px",
    width: "140px",
    padding: "8px 16px 8px 16px",
    top: "-20px",
    flexDirection: "column" as "column",
    gap: "8px",
    display: "flex",
  },
  addQuizButtonPhases: {
    textTransform: "capitalize" as "capitalize",
    display: "flex",
    gap: "8px",

  },
  addQuizButton: {
    width: "161px",
    height: "36px",
    padding: "10px 16px",
    textTransform: "capitalize" as "capitalize",
    display: "flex",
    backgroundColor: "#652786",
    borderRadius: "8px",
  },
}

const ModalMainBody = styled(Modal)({
  display:"flex",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center"
});

const ModalContent = styled(Box)({
  position: "absolute",
  maxWidth: "525px",
  width: "100%",
  height: "auto",
  padding: "9px",
  backgroundColor: "#fff",
  display: "flex",
  alignContent: "center",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "20px",
  paddingTop: "20px",
  paddingBottom: "25px",
  "@media (max-width: 656px)": {
    maxWidth:"400px",
  },
  "@media (max-width: 400px)": {
    maxWidth:"330px",
  }
});
const IconAlign = styled(Box)({
  position: "absolute",
  top: "-6",
  right: "-6",
  backgroundColor: "#652786",
  borderRadius: "50%",
  color: "white",
 
  "& .MuiSvgIcon-root":{
    fontSize:"20px",
  }
});
const ButtonGroup = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent:"space-between",
  gap: "23px",
  padding: "0px 2px 0px 2px"
});
const ModalText = styled(Typography)({
  width:"100%",
  display:"flex",
  fontStyle:"Poppins",
  fontWeight:500,
  fontSize:"22px",
  justifyContent:"left",
  padding: "5px 20px 5px 20px"
});

const ModalText1 = styled(Typography)({
  width:"100%",
  display:"flex",
  fontStyle:"Poppins",
  fontWeight:400,
  fontSize:"16px",
  justifyContent:"left",
  padding: "5px 20px 5px 20px"
});

const ButtonShape2 = styled(Button)({
  width: "48%",
  height: "42px",
  background: "1px solid ##652786 !important",
  borderRadius:"12px",
  fontWeight:600,
  fontSize:"16px",
  color: "#E47004",
  textTransform:"none",
});

const FiterImage = styled("img")({
});
interface HeadCell {
    id: keyof AttributesType;
    label: string;
  }
  const ButtonShape1 = styled(Button)({
    width: "48%",
    height: "42px",
    fontWeight:600,
    fontSize:"16px",
    background: "1px solid #652786 !important",
    border: "1px solid #E47004",
    borderRadius:"12px",
    color: "#E47004",
    textTransform:"none",
  });
const headCells: HeadCell[] = [
    { id: "title", label: "Course" },
    { id: "course_id", label: "ID" },
    {
      id: "xpert_name",
      label: "Xpert Name",
    },
    {
      id: "content_roles",
      label: "Content Role(s)",
    },
    {
      id: "total_chapters",
      label: "Lessons",
    },
    {
      id: "user_enrolled",
      label: "Users Enrolled",
    },
    {
      id: "updated_at",
      label: "Last Day Modified"
    },
    { id: "status", label: "Status" },
  ];
  
const NoDataImg = styled("img")({
        width: "616 !important",
        height: "404px !important"
});
const ImageComponent = styled("img")({
  width: "100%",
  height: "100%"
});
const ButtonBox = styled(Box)({
  padding:"10px 10px 0px 10px",
  width:"100%",
  "& .MuiButton-root:hover":{
    backgroundColor: "white",
  }
});

const BadgeComponents = styled(Badge)({
  "& .MuiBadge-anchorOriginTopRightRectangle": {
    top: "-14px",
    left: "20px",
    right: " 0px",
    backgroundColor: " #FF883F",
    fontSize: "81px",
    width: "10px",
    height: "10px",
    borderRadius: "20px"
  }
});
  const StyledContentWrapper = styled(Box)({
    position: "relative",
    display: "flex",
    maxWidth: "520px",
    width: "100%",

  "& .filterIconBox": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FAFAFA",
    minWidth: "62px",
    borderRadius: "8px",
    borderTopLeftRadius: "8px !important",
    borderBottomLeftRadius: "8px !important",
    cursor: "pointer",
    border: "1px solid #C9C6C5",
    position: "relative",
    zIndex: 999,
  },
  "& .filterIconBox:hover": {
    borderColor: '#652786'
  },
  "& .filterBorder": {
    borderColor: '#652786',
    backgroundColor: "#EEE8F2",
  },
});
const NotFoundText = styled(Typography)({
  marginTop: "26px",
  textAlign: "center",
  color: "#1C1B1B !important",
  fontFamily: "Poppins Arial, Helvetica, sans-serif",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "normal",
  letterSpacing: "-1px",
  marginBottom: "16px",
});

class ProgramContent extends CourseCreationController {
  constructor(props: Props) {

    super(props);
  }

  async componentDidMount() {
    super.componentDidMount();
    const urlPath = window.location.pathname;
    const pathSegments = urlPath.split('/'); // Splits the path into segments
    const id = pathSegments[pathSegments.length - 2]; // Extract the second last part for 'id'
    const type = pathSegments[pathSegments.length - 1]; // Extract the last part for 'type'
    if (id) {
      this.getProgramDetail(id);
    }
    if ( type === "Phases") {
      this.setState({
        phasesIncluded: true
      })
    }
  }
  renderCourseButton = () => {
    if (this.state.coursesSelected) {
      return (
        <Button
          onClick={() => this.handleCourseOpen(this.state.phaseID)}
          style={webStyle.AccordionCourseSummary}
          data-test-id="panel1a-content"
          aria-controls="panel1a-content"
          id="panel1a-add-header"
        >
          <CreateNewVideoComponent>
            <span
              style={{
                fontSize: "16px",
                fontWeight: 600,
                paddingRight: "10px",
                fontFamily: "Poppins, sans-serif",
                marginTop: '0',
              }}
            >
              +
            </span>
            Add Course
          </CreateNewVideoComponent>
        </Button>
      );
    }
    return null;
  };  

  renderContent = () => {
    const { coursesSelected,phasesIncluded} = this.state;  
    return (
<>
    {(!coursesSelected && !phasesIncluded) && (
       
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              rowGap: "32px",
            }}
          >
            <NoDataImg src={NotFoundImage} data-test-id="filterOpenTestID" />
            <Typography className="no-content">
              You don't have any content yet
            </Typography>
          </Box>
          <Button
            onClick={() => this.handleCourseOpen(this.state.phaseID)}
            style={webStyle.AccordionSummary}
            data-test-id="panel1a-content"
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <CreateNewVideoComponent>
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: 600,
                  paddingRight: "10px",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                +
              </span>
              Add Course
            </CreateNewVideoComponent>
          </Button>
        </Box>  
      )
    }
    
    {phasesIncluded  && (
      <Box style={webStyle.containtContainer2}>
      <PhaseDragDrop
              phasesContent={this.state.phasesContent}
              onDragEnd={this.onDragEnd}
              handleMouseEnter={this.handleMouseEnter}
              handleMouseLeave={this.handleMouseLeave}
              handleLessonDeleteDialog={this.handleLessonDeleteDialog}
              addDialogBoxopen={this.state.addDialogBoxopen}
              dialogImageToggle={this.state.dialogImageToggle}
              handleAddIconClick={this.handleAddIconClick}
              hoverLessonId={this.state.hoverLessonId}
              isHovering={this.state.isHovering}
              handleDragEnter={this.handleDragEnter}
              handleDragLeave={this.handleDragLeave}
              editingIndex={this.state.editingIndex}
              courseData={this.state.programs}
              showPhassesDropDown={this.state.showPhassesDropDown}
              handlePhaseMouseEnter={this.handlePhaseMouseEnter}
              hoverPhaseId={this.state.hoverPhaseId}
              handlePhaseDeleteDialog={this.handlePhaseDeleteDialog}
              handlePhaseAddIconClick={this.handlePhaseAddIconClick}
              phases={true}
              handleAddIconOpenLessonNew={this.handleAddIconOpenLessonNew}
              handleMouseLeavePhase={this.handleMouseLeavePhase}
              showPhasesDialogLesson={this.state.showPhasesDialogLesson}
              handleMouseEnterPhases={this.handleMouseEnterPhases}
              handleRenameText={this.handleRenameText}
              handleLeavePhaseRename={this.handleLeavePhaseRename}
              editingText={this.state.editingText}
              showRenameDialog={this.state.showRenameDialog}
              handleDropDown={this.handleDropDown}
              handleEnterPhaseRename={this.handleEnterPhaseRename}
              handleEditClick={this.handleEditClick}
              handleSaveName={this.handleSaveName}
              webStyle={webStyle}
              handleOpenLessson={this.handleCourseOpen}
              showEmptyText={this.showEmptyText}
              handleLessonOpen={this.handleCourseOpen}
              addedContent={this.renderCourseAddedContent()}
              courseSelected={this.state.coursesSelected}
              selectedPhaseId={this.state.phaseID}
              phaseCoursesMap= {this.state.phaseCoursesMap}
              handleLessonEdit={this.handleLessonEdit}
    />    
    </Box>
    )
  }
  </>
    )
  }
  renderCourseAddedContent = () => {
    if (this.state.coursesSelected) {
      const coursesToDisplay = this.state.phasesIncluded
      ? this.state.phaseCoursesMap[this.state.phaseID]
      : this.state.courseAdded;
      return (
        <>
        <TableBody style={{ overflowY: "auto", borderRadius: "18px", display: "block" }}>
  {coursesToDisplay?.map((row: any, index: any) => (
    <Box key={index} sx={{ mb: 1.25 }}>
      <TableRow
        key={index}
        hover
        style={{
          borderRadius: "12px",
          backgroundColor: "inherit",
          padding: this.state.phasesIncluded? "18px 64px" :"18px 32px",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          boxShadow: "1px 2px 10px 0px #00000011",
          borderCollapse: "separate", // Ensures that cells are not joined
        }}
      >
        <TableCell
          style={{
            fontWeight: 600,
            padding: "0 !important",
            fontSize: "18px",
            color: "#484646",
            display: "flex",
            alignItems: "center",
            gap: "24px",
            border: "none", // Removes the default table cell borders
            flex: 1, // Ensures the cell takes up available space
          }}
        >
          {row.name}
          <div
            style={{
              display: "flex",
              ...(this.getStatusStyle(row.status)),
              fontWeight: 400,
              fontSize: "12px",
              width: "96px",
              height: "40px",
              borderRadius: "88px",
              justifyContent: "center",
              alignItems: "center",
              maxWidth: "300px",
            }}
          >
            {this.toPascalCase(row.status)}
          </div>
        </TableCell>
        <Box
          style={{
            display: "flex",
            width: "59px",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <TableCell style={{ border: "none", padding: 0 }}>
            <img
              src={editNew}
              style={{ width: "24px", height: "24px", }}
            />
          </TableCell>

          <TableCell style={{ border: "none", padding: 0 }}>
            <Button onClick={() => this.handleDetach(row.id)}>
              <img
                src={UnlinkIcon}
                style={{ width: "24px", height: "24px" }}
                alt="Unlink Icon"
              />
            </Button>
          </TableCell>
        </Box>
      </TableRow>
    </Box>
  ))}
</TableBody>

        <DeleteModal
        data-test-id="Phase_modal"
        openDialog={this.state.RemovePhase}
        headingText={`Are you sure you want to detach ${this.state.selectedCourseName}?`}
        subHeading={"The course will no longer be linked to this program but can still be accessed directly."}
        btnCancelText={"Cancel"}
        btnOkText={"Detach From Program"}
        handleCancel={this.handlePhaseCancel}
        handleOk={this.handleRemovePhase}
      />
      </>
      );
    }
  }
  
  getMinWidthByIndex = (index:number) => {
    if (index === 0) return '166px';
    if (index === 1) return '145px';
    if (index === 2) return '152px';
    if (index === 6) return '100px';

  };
  getMaxWidthByIndex = (index:number) => {
    if (index === 3) return '160px';
    if (index === 4) return '100px';
    if (index === 5) return '100px';
    if (index === 6) return '120px';


  };
  getId = (index: number) => {
    return index === 4 ? "last" : "";
  }  
  getClassName = (index: number) => {
    return index === 0 ? "first" : "all";
  }
  renderFilterIcon = () => {
    const { anchorFilterContainerEl } = this.state; // or props if it's passed as a prop
  
    if (anchorFilterContainerEl) {
      return (
        <FiterImage
          src={Filter}
          data-test-id="filterOpenTestID"
          alt="Filter"
        />
      );
    } else {
      return (
        <img
          src={NewFilter}
          data-test-id="filterOpenTestID"
          alt="New Filter"
        />
      );
    }
  };  

  getBackgroundColor = (id:string) => {
    const { selectedRows } = this.state;
    return selectedRows.includes(id) ? '#EEE8F2' : 'inherit';
  }
shouldDisplaySortArrows = (index: number): boolean => {
    return ![4, 5, 7].includes(index);
  };
   getBackgroundColor2 = (isSelectButtonDisabled: boolean): string => {
    return isSelectButtonDisabled ? '#E5E2E1' : '#652786';
  };
  getTextColor = (isSelectButtonDisabled: boolean): string => {
    return isSelectButtonDisabled ? '#929090' : '#ffffff';
  };
  getBorderStyle = (isNoRowPresent: boolean): string => {
    return isNoRowPresent ? '1px solid #E5E2E1' : '1px solid #E47004';
  };
  getTextColor2 = (isNoRowPresent: boolean): string => {
    return isNoRowPresent ? '#929090' : '#E47004';
  };
  
  render() {
    const { anchorFilterContainerEl, courseResponse, selectedRows,coursesSelected } = this.state;
    const isNoRowPresent = !coursesSelected;
    const isSelectButtonDisabled = selectedRows.length === 0;
    const openFilterContainer = Boolean(this.state.anchorFilterContainerEl) 
   
    return (
      <>
    <div
     style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: this.state.phasesIncluded ? '6px 24px':'24px',
      boxShadow: this.state.coursesSelected ? '1px 2px 10px 0px #00000011' : '',
      borderRadius: '18px'
    }}>
      <Box style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}>
 <Box style={{
        display: "flex",
        justifyContent: "start",
        flexDirection: "row",
        alignItems: "center",
        columnGap: "24px",
        padding: this.state.phasesIncluded? "0px" : "24px",
      }}>
              <Typography className='program-title' data-test-id="program-title">
                  Program Title: {this.state.programTitle}
              </Typography>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", maxWidth: "300px" }}>
          <div style={this.statusOptionData(this.state.programStatus)}
                  >
                  {this.state.programStatus}
              </div>
          </div>
          </Box>
          {this.state.phasesIncluded && (
          <Box sx={webStyle.addPhasesButton}>
          <Box sx={webStyle.imageContainer}>
            <img src={BackgroundColor} alt="BackgroundColor" />
          </Box>
          <Button style={webStyle.addPhaseButtonText} data-testId="AddPhaseBtn" onClick={() => { this.handleAddPhase(this.state.programs.id, true) }}>Add phase</Button>
        </Box>
          )}
      {!this.state.phasesIncluded && this.renderCourseButton()}
      </Box>
      {!this.state.phasesIncluded && this.renderCourseAddedContent()}
      </div>
      {this.renderContent()}
    <Dialog
        open={this.state.showCoursePopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className='popup-dialog'
      >
          <Box style={{display:"flex", gap:"28px", flexDirection:"column"}}>
        <DialogTitle id="alert-dialog-title" style={{ padding: "0" }}>
          <Box className="heading_course_wrapper">
              <span>
                <img src={BackArrow} alt="back_img" style={{ cursor: 'pointer' }} onClick={this.handlePopupBackClose} />
              </span>
              <p className="heading_text">Select courses</p>
          </Box>
        </DialogTitle>
        <StyledContentWrapper  
       >
          <AdvancedSearch
            data-test-id="btn_change"
            isFilterDropActive={openFilterContainer}
            handleListItemClick={this.handleListItemClickSuggestion}
            searchInputValue={this.state.searchtext}
            handleInputChange={this.handleInputProgramChangeSearch}
            courseResponse={this.state.courseResponse}
            filterList={this.state.filteredList}
            showList={this.state.showList} navigation={undefined} id={""} />
          <div style={{ marginLeft: "-5px"}} data-test-id="filter-drop" className={anchorFilterContainerEl ? "filterIconBox filterBorder" : "filterIconBox"}  onClick={this.handleFilterDrop} aria-describedby={"myPopover"}>
            {this.state.creationDate.length > 0 || (this.state.creationDateParams.hasOwnProperty('start_date') && this.state.creationDateParams.start_date.length > 0) ||this.state.categorySelectedItems.length > 0|| this.state.placeholderSelectedItems.length > 0  || this.state.contentRoleSelectedItems.length > 0 || this.state.selectedStatus.length > 0 ? (
              <BadgeComponents color="secondary" variant="dot"></BadgeComponents>) : (
              ""
            )
            }
          {this.renderFilterIcon()}
          </div>
          <Popover
            data-test-id="filterPropID"
            id="myPopover"
            open={openFilterContainer}
            className="filterContainerPopover"
            onClose={this.filterContainerDropdownCloseHandler}
            anchorEl={this.state.anchorFilterContainerEl}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
          >
               <FilterItems
              data-test-id="FilterItemsTestID"
              isContentRole={this.state.contentRoleTrue}
              selectedStatus={this.state.selectedStatus}
              categorySelectedItems={this.state.categorySelectedItems}
              contentRoleSelectedItems={this.state.contentRoleSelectedItems}
              categorySearchText={this.state.categorySearchText}
              handleChangeStatus={this.handleCatalogueChangeStatus}
              handleContentRoleSearchChange={this.handleContentRoleChangeSearch}
              handleCategorySearchChange={this.handleCategoryChangeSearch}
              contentRoleSearchText={this.state.contentRoleSearchText}
              placeholderSelectedItems={this.state.placeholderSelectedItems}
              placeholderCheckboxChangeHandler={this.placeholderCheckboxAssignedExpertChangeHandler}
              handlePlaceholderSearchChange={this.handlePlaceholderAssignedSearchChange}
              contentRoleCheckboxChangeHandler={this.contentRoleCheckboxHandleChange}
              creationDateChangeHandler={this.creationChangeHandlerDate}
              dropdownOpenHandler={this.dropdownHandlerFilterOpen}
              dropdownCloseHandler={this.dropdownHandlerClose}
              handleClick={this.handleClickFilter}
              categoryCheckboxChangeHandler={this.categoryCheckboxHandlerChange}
              handleCalendarOpen={this.handleOpenCalendor}
              anchorEl={this.state.anchorElPopup}
              dropdownType={this.state.dropdownType}
              placeholderSearchText={this.state.placeholderSearchText}
              creationDate={this.state.creationDate}
              expertAdminList={this.state.expertAdminList}
              adminCatagoryList={this.state.adminCatagoryList}
              adminContentRolesList={this.state.adminContentRolesList}
              isCalendarOpen={this.state.isCalendarOpen}
              handleClear={this.handleClearFilter}
              handleCalendarChange={this.handleChangeCalendor} />
          </Popover>

        </StyledContentWrapper>
      </Box>
      {courseResponse.length === 0 ? (
<>
  <Box style={{justifyContent:'center',display:'flex', marginTop: "64px"}}>
    <NoDataImg src={NotFoundImage} />
  </Box>
  <NotFoundText>No results </NotFoundText>
  <Typography style={{justifyContent:"center", display: "flex", fontWeight: 400, fontSize: "16px", color:"#787776"}}>Try and widen your search</Typography>
</>
) : (
<>
      <TableHead>
            <TableRow>
            {headCells.map((header,index) => {
              return (
                  <TableCell
                      key={index}
                      id={this.getId(index)}
                      style={{ minWidth: this.getMinWidthByIndex(index),whiteSpace:"nowrap", maxWidth: this.getMaxWidthByIndex (index), textAlign: "center" }}
                      className={this.getClassName(index)}
                  >
                  <TableSortLabel direction={"asc"} style={{ display: "flex", flexDirection: 'row', alignItems: 'center', gap:"8px" }}>
                    <span  style={{ display: "flex", justifyContent: "space-between", whiteSpace: "nowrap", fontWeight: 600, fontStyle: "normal", lineHeight: "24px", color: "#000", fontFamily: "Poppins, sans-serif" }}>{header.label}</span>
                    {this.shouldDisplaySortArrows(index) && (
                    <div style={{
                      display: 'flex',
                      flexDirection: 'column', 
                      height: '17px',
                      marginTop: '2px',
                    }}>
                      <img
                        src={UpArrowIcon}
                        alt="Up Arrow"
                        width="10px"
                        data-test-id={`testing${index}`}
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.handleSort(header.id)}
                      />
                    </div>
                    )}
                  </TableSortLabel>
                </TableCell>
              )
            })}

          </TableRow>
      </TableHead>
      <TableBody style={{ height: '728px', overflowY: 'auto', overflowX: "hidden"}}>
      {courseResponse?.map((row, index) => {
        const labelId = `checkbox-${index}`;

        return (
          <Box key={index} sx={{ mb: 1.25 }}>
          <TableRow
            key={index}
              hover
              style={{
                  overflow: 'hidden',
                  width: '1100px',
              }}
          >
           <TableCell style={{
               display:'flex',
               borderTopLeftRadius: '12px',
               borderBottomLeftRadius: '12px',
               backgroundColor: this.getBackgroundColor(row.course_id),
               width:"210px",
               justifyContent:"space-between",
               alignItems:"center"
            }}>
            <Checkbox
            checked={selectedRows.includes(row.course_id)}
            onChange={(event) => this.handleCheckboxChange(row.course_id, row.title, row.status,this.state.phaseID)(event)}
            inputProps={{ 'aria-labelledby': labelId }}
              className='popup-checkbox'
              data-test-id="checkbox_phases"
            />
<img
  src={row?.thumbnail_image?.url}
  alt={row?.title}
  style={{ width: '40px', height: '40px', objectFit: 'cover', marginRight: '8px' }}
/>
{row.title}
</TableCell>
            <TableCell style={{minWidth:"145px",maxWidth: "160px",textAlign: "center", backgroundColor: this.getBackgroundColor(row.course_id),}}>{row.number || "--"}</TableCell>
            <TableCell style={{minWidth:"118px",textAlign: "center", backgroundColor: this.getBackgroundColor(row.course_id),}}>{row.xpert_name}</TableCell>
            <TableCell style={{maxWidth:"176px", minWidth: "176px", textAlign: "center", backgroundColor: this.getBackgroundColor(row.course_id),}}>{row.content_roles}</TableCell>
            <TableCell  style={{minWidth:"70px",maxWidth:"80px",textAlign: "center", backgroundColor: this.getBackgroundColor(row.course_id),}}>{row.total_chapters}</TableCell>
            <TableCell style={{minWidth:"70px",maxWidth:"80px",textAlign: "center", backgroundColor: this.getBackgroundColor(row.course_id),}}>{row.user_enrolled}</TableCell>
            <TableCell style={{minWidth:"100px",textAlign: "center", backgroundColor: this.getBackgroundColor(row.course_id),}}>{this.formatDate(row.updated_at)}</TableCell>
            <TableCell style={{ 
              backgroundColor: this.getBackgroundColor(row.course_id),
              borderTopRightRadius: '12px',
               borderBottomRightRadius: '12px',}}>
              <div style={{ display: "flex", ...(this.getStatusStyle(row.status)), width: '96px',height: '40px',borderRadius: '88px',justifyContent: "center", alignItems: "center", maxWidth: "300px" }}>
                {this.toPascalCase(row.status)}
            </div>
                </TableCell>
          </TableRow>
          </Box>
        );
      })}
    </TableBody>
    
    <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '16px', gap: '24px' }}>
        <Button
        className='btn-popup'
         style={{
  width: '227px',
  height: '56px',
  borderRadius: '16px',
  border: '1px solid #E47004',
  color: '#E47004',
  textTransform: 'capitalize',
}}
onClick={this.handlePopupClose}>
          Cancel
        </Button>
        <Button variant="contained" 
        className='btn-popup'
        style={{
          width: '227px',
          height: '56px',
          borderRadius: '16px',
          backgroundColor: this.getBackgroundColor2(isSelectButtonDisabled),
          color: this.getTextColor(isSelectButtonDisabled),
          textTransform: 'capitalize',
        }}
         onClick={this.handleSelectCourse}
         disabled={isSelectButtonDisabled}>
          Select
        </Button>
    </Box>
    </>
)}
      </Dialog>
   <Box style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        columnGap: "16px",
        marginTop: "31px",

      }}>
        <Button 
         style={{
           display: 'flex',
           width: '345px',
           height: '56px',
           justifyContent: 'center',
           alignItems: 'center',
           gap: '10px',
           backgroundColor: 'transparent', // 'none' is not valid; use 'transparent'
           borderRadius: '16px',
           border: this.getBorderStyle(isNoRowPresent),
           color: this.getTextColor2(isNoRowPresent),
           marginTop: '16px',
           fontSize: '16px',
           fontWeight: 600,
           textTransform: 'none', 
        }}
         disabled={isNoRowPresent}>
          Save to Draft
          </Button>
        <Button  style = {{
          display: 'flex',
          width: '345px',
          height: '56px',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '10px',
          borderRadius: '16px',
          marginTop: '16px',
          textTransform: 'capitalize',
          fontSize: '16px',
          fontWeight: 600,
          ...this.getRowStyles(isNoRowPresent),
        }}
         disabled={isNoRowPresent}>Create Program</Button>
  </Box>
  <ModalMainBody

        open={this.state.openPhaseDelete}
        onClose={this.cancelPhaseDeleteDialog}>
        <ModalContent>
          <IconAlign onClick={this.cancelPhaseDeleteDialog}><CloseIcon /></IconAlign>
          <ModalText>{`Are you sure you want to delete this ${this.state.phaseName} ?`}</ModalText>
          <ModalText1>{"All courses within this phase will be permanently deleted. Content is retained in the content library."}</ModalText1>
          <ButtonBox>
          <ButtonGroup>
            <ButtonShape2 onClick={()=>this.handleDeletePhase(this.state.phaseID)} style={{color:"white", background:"#652786"}}>Delete</ButtonShape2>
            <ButtonShape1 onClick={this.cancelPhaseDeleteDialog}>Cancel</ButtonShape1>
          </ButtonGroup>
          </ButtonBox>
        </ModalContent>

      </ModalMainBody>
      </>
    );
  }

  getRowStyles(isNoRowPresent: boolean) {
    return {
      backgroundColor: isNoRowPresent ? '#E5E2E1' : '#652786',
      color: isNoRowPresent ? '#929090' : '#ffffff',
    };
  }
  
    statusOptionData(row: any): React.CSSProperties | undefined {
            if(row ==="active"){
              return webStyle.statusNew
            }else if(row === "draft"){
              return webStyle.statusDraft
            }else {
              return webStyle.statusArchived
            }
          }
    };

export default ProgramContent;
